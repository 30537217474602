<template>
  <Layout>
    <div class="content">
      <h4 class="title">
        模块信息
      </h4>
      <el-form
        ref="form"
        v-loading="fetchLoading"
        :model="formData"
        :rules="rules"
        size="small"
        label-width="100px"
        label-position="left"
      >
        <el-form-item
          label="排序"
          prop="sort"
        >
          <el-input-number
            v-model="formData.sort"
            :min="0"
            label="排序"
          />
        </el-form-item>
        <el-form-item
          label="所属类目"
        >
          <el-select
            v-model="formData.pId"
            placeholder="所属类目"
            clearable
            disabled
          >
            <el-option
              v-for="item in positionList"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          label="列表样式"
          prop="style"
        >
          <el-radio-group
            v-model="formData.style"
            style="display: flex; flex-wrap: wrap;"
          >
            <div
              v-for="(item, index) in styleForAdList"
              :key="index"
              class="style-items"
            >
              <label>
                <el-radio
                  :label="item.value"
                  class="radio"
                >
                  <el-image
                    style="width: 100px; height: 50px"
                    :src="item.example"
                    fit="cover"
                    draggable="false"
                  />
                  <span>{{ item.name }}</span>
                </el-radio>
              </label>
            </div>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="展示数量"
          prop="limitNum"
        >
          <el-input-number
            v-model="formData.limitNum"
            :min="0"
            label="展示数量"
          /> （0:不限制，非0:展示数量）
        </el-form-item>
        <el-form-item
          label="状态"
          prop="status"
        >
          <el-switch
            v-model="formData.status"
            :active-value="1"
            :inactive-value="2"
            active-text="启用"
            inactive-text="禁用"
          />
        </el-form-item>
      </el-form>
      <h4 class="title title-2">
        多语言配置
      </h4>
      <el-tabs
        v-model="currentLang"
        type="card"
      >
        <el-tab-pane
          v-for="(item, index) in langList"
          :key="index"
          :label="item.name"
          :name="item.value"
        >
          <I18NLangFormADModule
            ref="subForm"
            :key="index"
            :lang="item.value"
            :form="I18NFieldMap[item.value]"
            @validateFail="handleValidateFail"
          />
        </el-tab-pane>
      </el-tabs>
      <div class="ctrl-area">
        <el-button
          :loading="updateLoading"
          type="primary"
          @click="handleSubmit"
        >
          保存
        </el-button>
        <el-button @click="handleCancel">
          取消
        </el-button>
      </div>
    </div>
  </Layout>
</template>

<script>
import { styleForAdList, langList } from '@/utils/selectOptions'
import { mapState } from 'vuex'
import I18NLangFormADModule from './components/I18NLangFormADModule'
import { cloneDeep } from 'lodash'
import { queryCategoryItemADInfo, updateCategoryItemADInfo } from '@/api/APP_Category'

const initLangForm = () => {
  return langList.reduce((maps, item) => {
    maps[item.value] = {}
    return maps
  }, {})
}

export default {
  name: 'CreateOrEditADModule',
  components: { I18NLangFormADModule },
  data () {
    return {
      styleForAdList,
      positionList: [],
      langList,
      formData: {
        limitNum: 0,
        status: '2',
        level: 2
      },
      isADModuleEdit: true,
      rules: {
        sort: [{ required: true, message: '排序必填' }],
        style: [{ required: true, message: '样式必选' }],
        limitNum: [{ required: true, message: '展示数量必填' }]
      },
      updateLoading: false,
      I18NFieldMap: initLangForm(),
      fetchLoading: false,
      currentLang: 'en'

    }
  },
  computed: {
    ...mapState('app', ['appId', 'channel'])
  },
  created () {
    this.formData.pId = Number(this.$route.query.pId)
    this.formData.zoneId = Number(this.$route.query.zoneId)
    this.positionList = [
      { name: this.$route.query.name || 'Error', value: Number(this.$route.query.pId) }
    ]
    this.isADModuleEdit = this.$route.name === 'RealADModuleEdit'
    if (this.isADModuleEdit) {
      this.handleQueryCategoryItemADInfo()
    }
  },
  methods: {
    handleQueryCategoryItemADInfo () {
      const id = this.$route.query.id
      this.fetchLoading = true
      queryCategoryItemADInfo({
        id,
        appId: this.appId,
        channel: this.channel,
        moduleType: 2
      })
        .then(res => {
          if (res.code === 200) {
            this.formData = res.data
            this.formData.zoneId = Number(this.$route.query.zoneId)
            this.formData.zoneId = Number(this.$route.query.zoneId)
            this.langList.forEach(lang => {
              const title = this.formData.titleDetail[lang.value]
              this.I18NFieldMap[lang.value] = {
                title
              } || {}
            })
          }
        })
        .finally(() => {
          this.fetchLoading = false
        })
    },
    handleCheckAllForm () {
      const checkAll = (resolve) => {
        Promise.all(this.$refs.subForm.map(item => item.validate()))
          .then((valid) => {
            if (valid) {
              resolve()
            } else {
              this.$message.error('校验未通过, 请检查表单后再试')
            }
          }).catch(err => {
            console.log(err)
          })
      }
      return new Promise(resolve => {
        this.$refs.form.validate()
          .then(valid => {
            if (valid) {
              checkAll(resolve)
            } else {
              this.$message.error('校验未通过, 请检查表单后再试')
            }
          })
      })
    },
    handleSubmit () {
      this.handleCheckAllForm()
        .then(() => {
          this.updateLoading = true
          const formData = this.preFormatFormData()
          updateCategoryItemADInfo({
            ...formData,
            appId: this.appId,
            channel: this.channel,
            moduleType: 2
          })
            .then(res => {
              if (res.code === 200) {
                this.$message.success('新建或者更新成功')
                this.$router.back()
              }
            })
            .finally(() => {
              this.updateLoading = false
            })
        })
    },
    handleCancel () {
      this.$router.back()
    },
    // 校验同级表单时, 切换到第一个报错的表单项
    handleValidateFail (lang) {
      if (this.isValidateFail) return
      this.isValidateFail = true
      setTimeout(() => {
        this.isValidateFail = false
        this.currentLang = lang
      })
    },
    // 上传前处理表单数据, 以符合接口预期
    preFormatFormData () {
      const formData = cloneDeep(this.formData)
      const I18NFieldMap = this.I18NFieldMap
      formData.titleDetail = Object.keys(I18NFieldMap).reduce((maps, lang) => {
        maps[lang] = I18NFieldMap[lang].title || ''
        return maps
      }, {})
      return formData
    }
  }
}
</script>

<style scoped lang="less">
  .content {
    margin: 50px 80px;

    h4.title {
      margin-bottom: 40px;

      &.title-2 {
        margin-top: 60px;
      }
    }

    .el-input,
    .el-textarea {
      width: 420px;
    }
    .ctrl-area{
      margin-top: 80px;
      .el-button{
        padding:10px  40px;
      }
    }
  }
</style>
<style lang="less">
  .style-items{
    @w:160px;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    width: @w;
    height: @w;
    margin-right: 20px;
    border-radius: 6px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    label{
      display: inline-block;
      width: 100%;
      height: 100%;
    }
    .radio{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .el-radio__label{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .el-image{
          margin: 10px 0 20px;
        }
      }
    }
  }
</style>
